<!-- @Author: Yu_Bo -->
<template>
    <div class='account_add_edit zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="604px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="inv_main">
                <div class="main_text">
                    <div class="left"><span>*</span>名称</div>
                    <div class="right">
                        <el-input v-model="info.name" maxlength="10" placeholder="请输入名称">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>会员类型</div>
                    <div class="right">
                        <el-select v-model="info.type" placeholder="请选择">
                            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>原价</div>
                    <div class="right">
                        <el-input v-model="info.original_price" maxlength="10" @input="limitNum1" placeholder="请输入原价">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>现价</div>
                    <div class="right">
                        <el-input v-model="info.current_price" maxlength="10" @input="limitNum2" placeholder="请输入现价">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left"><span>*</span>会员权益</div>
                    <div class="right">
                        <el-input type="textarea" placeholder="多条权益请用多行展示" rows="6" v-model="value"></el-input>
                    </div>
                </div>
            </div>
            <div slot="footer" style="padding: 0 20px;">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        type: {
            type: Number,
            defaul: 1
        },
        typeList: {
            type: Array,
            defaul: []
        },
    },
    data() {
        return {
            dialogVisible: false,
            info: {
                name: "",
                type: "",
                original_price: "",
                current_price: "",
                rights: "",
            },
            value: "",
            id: "",
            btnLoading: false,
        }
    },
    computed: {
        title() {
            return this.type == 1 ? '新增' : '编辑'
        },
    },
    watch: {},
    created() { },

    methods: {
        // 打开弹框
        openDialogBtn(val) {
            if (val) {
                this.info = {
                    name: val.name,
                    type: val.type,
                    original_price: val.original_price,
                    current_price: val.current_price,
                    rights: val.rights,
                }
                let str = val.rights.toString(',')
                this.value = str.replace(/,/g, '\n');
                this.id = val.id
            }
            this.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            this.info = {
                name: "",
                type: "",
                original_price: "",
                current_price: "",
                rights: "",
            }
            this.value = ""
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            console.log(this.value)
            var that = this
            // 去掉空格 不能为空
            let str = that.value.replace(/\s*/g, "");
            if (str == '') {
                that.$errMsg('请输入会员权益')
                return
            }
            // 换行符转逗号
            let newStr = that.value.replace(/\n/g, ',');
            // 转数组
            that.info.rights = newStr.split(',')
            if (that.info.name == '') {
                that.$errMsg('请输入名称')
                return
            } else if (that.info.type == '') {
                that.$errMsg('请选择会员类型')
                return
            } else if (that.info.original_price == '') {
                that.$errMsg('请输入原价')
                return
            } else if (that.info.current_price == '') {
                that.$errMsg('请输入现价')
                return
            } else if (that.type == 1) {
                // 添加
                that.btnLoading = true
                var params = {
                    ...that.info
                }
                that.$memberApi.memberConfigAdd(params).then(res => {
                    that.btnLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('submit')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.type == 2) {
                // 编辑
                that.btnLoading = true
                var params = {
                    ...that.info
                }
                that.$memberApi.memberConfigEdit(params, that.id).then(res => {
                    that.btnLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('submit')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },

        // 输入框限制数字
        limitNum1(e) {
            this.info.original_price = e.replace(/[^0-9.]/g, '')
        },
        limitNum2(e) {
            this.info.current_price = e.replace(/[^0-9.]/g, '')
        },
    },
}
</script>

<style lang='scss' scoped>
.account_add_edit {
    .inv_main {
        width: 100%;
        padding: 10px 20px 0 0;

        .main_text {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            .left {
                width: 100px;
                line-height: 40px;
                text-align: right;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

                span {
                    color: #e00c25;
                }
            }

            .right {
                width: 420px;

                .el-input {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }
                .el-cascader {
                    width: 100%;
                }
                .right_txt {
                    padding-top: 10px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ff2b2b;
                }

                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    text-align: center;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
            }
        }
        .main_text:last-child {
            margin-bottom: 0;
        }
    }
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}

::v-deep .el-switch {
    margin-top: 8px;
}
</style>
